body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  height: 100vh;
  margin-left: 1em;
  margin-right: 1em;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.card {
  margin: 1em;
  text-shadow: 2px 2px 3px rgba(0,0,0,0.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.card:hover {
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
  text-shadow: 2px 2px 3px rgba(0,0,0,0.1);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
  transition: all 0.5s ease;
}


.card .card-header {
  color: blue;
  background-color: white;
  border: 0px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.tooltips .groupInfo .icon {
  color: dodgerblue;
  font-size: 20px;
  cursor: pointer;
  margin: 10px;
}
